import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default class SlideInModal extends React.PureComponent {
	static instance = null;

	static show = (
		onShowCb = () => {},
		header = <div />,
		body = <div />,
		footer = <div />,
		closable = true,
	) => {
		onShowCb();
		if (SlideInModal.instance) {
			SlideInModal.instance.setState({ visible: false }, () => {
				SlideInModal.instance.setState({
					visible: true,
					header,
					body,
					footer,
					closable,
				});
			});
		}
	};

	static hide = (onHideCb = () => {}) => {
		if (SlideInModal.instance) {
			SlideInModal.instance.setState({ visible: false });
			const timeout = setTimeout(() => {
				onHideCb();
				clearTimeout(timeout);
			}, 300);
		}
	};
	static getIsShow = () => {
		return SlideInModal.instance.state?.visible || false;
	};

	constructor(props) {
		super(props);
		SlideInModal.instance = this;

		this.state = {
			visible: false,
			header: <div />,
			body: <div />,
			footer: <div />,
			closable: true,
		};
	}

	render() {
		return (
			<Modal
				isOpen={SlideInModal?.instance?.state?.visible || false}
				toggle={() => SlideInModal.hide()}
				className="modal-dialog-centered"
				modalClassName={"modal-danger"}
				backdrop={SlideInModal?.instance?.state?.closable ? true : "static"}
			>
				{this.state.closable ? (
					<ModalHeader toggle={() => SlideInModal.hide()}>
						{this.state.header ? this.state.header : <div />}
					</ModalHeader>
				) : (
					this.state.header || <div />
				)}

				<ModalBody>{this.state.body ? this.state.body : <div />}</ModalBody>
				<ModalFooter>
					{this.state.footer ? this.state.footer : <div />}
				</ModalFooter>
			</Modal>
		);
	}
}
